// extracted by mini-css-extract-plugin
export var wrapper = "Header-module--wrapper--1P--0";
export var container = "Header-module--container--1eDDo";
export var body = "Header-module--body--2gVnH";
export var divShortVertical = "Header-module--div-short-vertical--1P84G";
export var divShort = "Header-module--div-short--Qt4yZ";
export var divFull = "Header-module--div-full--2M_5h";
export var themeDivPattern = "Header-module--theme-div-pattern--1oKU3";
export var logoContainer = "Header-module--logo-container--1o8vq";
export var randomShapeA = "Header-module--random-shape-a--3tjJG";
export var randomShapeB = "Header-module--random-shape-b--3ZR6q";
export var header = "Header-module--header--NwnPE";