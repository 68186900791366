// extracted by mini-css-extract-plugin
export var wrapper = "Footer-module--wrapper--3I0yu";
export var container = "Footer-module--container--29Iy5";
export var body = "Footer-module--body--2ZojK";
export var divShortVertical = "Footer-module--div-short-vertical--3qrmr";
export var divShort = "Footer-module--div-short--1DKWY";
export var divFull = "Footer-module--div-full--gDRIp";
export var themeDivPattern = "Footer-module--theme-div-pattern--odNuk";
export var logoContainer = "Footer-module--logo-container--2wVVX";
export var randomShapeA = "Footer-module--random-shape-a--38Cj6";
export var randomShapeB = "Footer-module--random-shape-b--1NJho";
export var footer = "Footer-module--footer--1bJd8";