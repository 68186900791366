// extracted by mini-css-extract-plugin
export var wrapper = "index-module--wrapper--3dib_";
export var container = "index-module--container--PqeXv";
export var body = "index-module--body--wuoZu";
export var divShortVertical = "index-module--div-short-vertical--1RKW3";
export var divShort = "index-module--div-short--3c9KI";
export var divFull = "index-module--div-full--3I3vG";
export var themeDivPattern = "index-module--theme-div-pattern--2XkFm";
export var logoContainer = "index-module--logo-container--1OKTj";
export var randomShapeA = "index-module--random-shape-a--2HZKP";
export var randomShapeB = "index-module--random-shape-b--3amaG";
export var latest = "index-module--latest--3PKy0";
export var tags = "index-module--tags--GSmIK";
export var excerpt = "index-module--excerpt--2KXkV";
export var meta = "index-module--meta--2p3-2";
export var date = "index-module--date--2-4hn";